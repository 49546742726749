import React, { useContext, useState } from 'react'
import {
  Typography,
  withStyles,
  Grid,
  Button,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { getAssessmentParts } from 'efqm-theme/assessments/getAssessmentParts'
import { Link } from 'gatsby'
import HelpIcon from '@material-ui/icons/Help'
import get from 'lodash/get'
import { Redirect } from '@reach/router'
import {
  AuthContext,
  useAuthorizedQuery,
  TypedChip,
  PaddedContainer,
  SectionTitle,
  useAdminTable,
  UserFilter,
} from 'gatsby-components'

import { getAssessmentId } from '../utils/url'
import ContextualHelp from '../components/ContextualHelp'
import SEO from '../components/SEO'
import {
  getUnassignedContributorsAssessorsData,
  getAssessmentContributorsAssessorsData,
  getShallowAssessmentData,
  insertAssessmentContributorMutation,
  deleteAssessmentContributorMutation,
  insertAssessmentAssessorMutation,
  deleteAssessmentAssessorMutation,
} from '../queries'
import { useMutation } from 'graphql-hooks'

function ContributorsAssessorsTemplate({
  location,
  theme,
  classes,
  pageContext: { assessment: contextAssessment },
}) {
  const [filterText, setFilterText] = useState('')
  const { t, i18n } = useTranslation()
  const { assessment } = getAssessmentParts(contextAssessment.key, i18n)

  const assessmentId = getAssessmentId(location)
  const { getUserTokenData } = useContext(AuthContext)
  const { isAdmin, isEfqmAdmin } = getUserTokenData()

  const { data: assessmentData } = useAuthorizedQuery(
    getShallowAssessmentData,
    { id: assessmentId },
    {
      onPreFetch: variables => !!variables.id,
      onFetch: data => get(data, 'assessment_by_pk'),
    }
  )

  const contributorAssessorGroupPossibilities = (function() {
    const arr = []
    ;[
      'group.id',
      'group.parent_group.id',
      'group.parent_group.parent_group.id',
    ].forEach(s => {
      const groupId = get(assessmentData, s)
      if (groupId) arr.push(groupId)
    })
    return arr
  })()
  const contributorAssessorOrganizationPossibilities = (function() {
    const arr = []
    ;[
      'group.organization.id',
      'group.organization.parent_organization.id',
      'group.organization.parent_organization.parent_organization.id',
    ].forEach(s => {
      const groupId = get(assessmentData, s)
      if (groupId) arr.push(groupId)
    })
    return arr
  })()

  const {
    data: assessorsData,
    refetch: fetchAssessmentContributorsAssessorsData,
  } = useAuthorizedQuery(
    getAssessmentContributorsAssessorsData,
    { assessmentId },
    {
      onPreFetch: variables => !!variables.assessmentId,
    }
  )

  const assessors = get(assessorsData, 'assessors', [])
  const contributors = get(assessorsData, 'contributors', [])

  const canEditAssesors =
    contextAssessment.key !== 'questionnaire' &&
    contextAssessment.key !== 'questionnaire-2020' &&
    (isAdmin || isEfqmAdmin)
  const canEditContributors = isAdmin || isEfqmAdmin

  const headers = [
    { id: 'id', label: 'ID' },
    { id: 'email', label: 'Email' },
    { id: 'group', label: 'Group' },
    { id: 'first_name', label: 'First Name' },
    { id: 'last_name', label: 'Last Name' },
  ]
  if (canEditContributors) {
    headers.push({ id: 'contributor', label: 'Contributor' })
  }
  if (canEditAssesors) {
    headers.push({ id: 'assessor', label: 'Assessor' })
  }
  const [insertAssessmentContributor] = useMutation(
    insertAssessmentContributorMutation
  )
  async function assignContributor(user) {
    const variables = { assessmentId, contributorId: user.id }
    await insertAssessmentContributor({ variables })
    refetchUnassigned()
    fetchAssessmentContributorsAssessorsData({ assessmentId })
  }

  const [deleteAssessmentContributor] = useMutation(
    deleteAssessmentContributorMutation
  )
  async function unassignContributor(user) {
    const variables = { assessmentId, contributorId: user.id }
    await deleteAssessmentContributor({ variables })
    refetchUnassigned()
    fetchAssessmentContributorsAssessorsData({ assessmentId })
  }

  const [insertAssessmentAssessor] = useMutation(
    insertAssessmentAssessorMutation
  )
  async function assignAssessor(user) {
    const variables = { assessmentId, assessorId: user.id }
    await insertAssessmentAssessor({ variables })
    refetchUnassigned()
    fetchAssessmentContributorsAssessorsData({ assessmentId })
  }

  const [deleteAssessmentAssessor] = useMutation(
    deleteAssessmentAssessorMutation
  )
  async function unassignAssessor(user) {
    const variables = { assessmentId, assessorId: user.id }
    await deleteAssessmentAssessor({ variables })
    refetchUnassigned()
    fetchAssessmentContributorsAssessorsData({ assessmentId })
  }

  const { table, refetch: refetchUnassigned } = useAdminTable({
    query: getUnassignedContributorsAssessorsData,
    variables: {
      assessmentId,
      groupIds: contributorAssessorGroupPossibilities,
      organizationIds: contributorAssessorOrganizationPossibilities,
      filter: `%${filterText}%`,
      isEfqmWhereClause: isEfqmAdmin ? {} : { _not: {} },
    },
    headers,
    renderTableBody: data => {
      return data.user.map(user => {
        return (
          <TableRow key={user.id}>
            <TableCell>{user.id}</TableCell>
            <TableCell>
              <Typography>{user.email}</Typography>
            </TableCell>
            <TableCell>
              {get(user, 'user_group2s[0].group.name', '-')}
            </TableCell>
            <TableCell>{user.first_name}</TableCell>
            <TableCell>{user.last_name}</TableCell>
            {canEditContributors ? (
              <TableCell>
                <Button
                  onClick={e => assignContributor(user)}
                  color="secondary"
                  variant="outlined"
                >
                  {t('Select')}
                </Button>
              </TableCell>
            ) : null}
            {canEditAssesors ? (
              <TableCell>
                <Button
                  onClick={e => assignAssessor(user)}
                  color="secondary"
                  variant="outlined"
                >
                  {t('Select')}
                </Button>
              </TableCell>
            ) : null}
          </TableRow>
        )
      })
    },
  })

  if (!(assessmentId || isAdmin)) {
    return <Redirect to="/auth" noThrow />
  }

  return (
    <>
      <SEO title={get(assessmentData, 'name', 'Contributors and Assessors')} />
      <PaddedContainer>
        <Button
          component={Link}
          to={`/assessment/${assessment.key}/#${assessmentId}`}
          color="secondary"
        >
          ◀ {t('Assessment Home')}
        </Button>
        <div className={classes.section}>
          <Grid container spacing={4} direction="column">
            <Grid item xs={3}>
              <SectionTitle
                barColor={theme.palette.primary.dark}
                className={classes.sectionTitle}
                gutterBottom
              >
                {assessment.name}
                {assessment.guidance && (
                  <ContextualHelp helpContent={assessment.guidance}>
                    <HelpIcon color="secondary" className={classes.helpIcon} />
                  </ContextualHelp>
                )}
              </SectionTitle>
            </Grid>
            <Grid item xs>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="h4">
                    {t(
                      get(assessmentData, 'internal')
                        ? 'internal assessment name'
                        : 'external assessment name'
                    )}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="h2" color="primary">
                    {get(assessmentData, 'name', t('Loading...'))}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.section}>
          <Typography variant="h1">
            {t(`Assign Contributors${canEditAssesors ? ' and Assessors' : ''}`)}
          </Typography>
        </div>
        <div className={classes.section}>
          <Grid container>
            <Grid item xs={12} className={classes.participants}>
              {assessors.map(({ assessor }) =>
                assessor ? (
                  <TypedChip
                    key={assessor.id}
                    name={`${assessor.first_name} ${assessor.last_name}`}
                    onDelete={
                      canEditAssesors ? () => unassignAssessor(assessor) : null
                    }
                    type={t('Assessor')}
                    color="primary"
                  />
                ) : null
              )}
              {contributors.map(({ contributor }) =>
                contributor ? (
                  <TypedChip
                    key={contributor.id}
                    name={`${contributor.first_name} ${contributor.last_name}`}
                    onDelete={
                      canEditContributors
                        ? () => unassignContributor(contributor)
                        : null
                    }
                    type={t('Contributor')}
                    color="secondary"
                  />
                ) : null
              )}
            </Grid>
            <Grid item xs={12} className={classes.filterContainer}>
              <UserFilter setFilterText={setFilterText} />
              <Button
                component={Link}
                to={`/assessment/${assessment.key}/#${assessmentId}`}
                color="secondary"
                variant="contained"
              >
                {t('Close')}
              </Button>
            </Grid>
          </Grid>
          {table}
        </div>
      </PaddedContainer>
    </>
  )
}

const styles = theme => ({
  section: {
    margin: theme.spacing(3, 0),
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  helpIcon: {
    marginLeft: theme.spacing(1),
  },
  keyInformationInput: {
    marginBottom: theme.spacing(2),
  },
  sectionProgress: {
    color: theme.palette.primary.dark,
  },
  filesSeparator: {
    borderLeft: `solid 1px ${theme.palette.background.light}`,
  },
  keyInformationHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  buttonBar: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  displayNone: {
    display: 'none',
  },
  switch: {
    height: theme.spacing(4),
  },
  filterContainer: {
    margin: theme.spacing(1),
    marginLeft: 0,
    display: 'flex',
    alignItems: 'flex-end',
  },
  participants: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginRight: -theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
})

export default withStyles(styles, { withTheme: true })(
  ContributorsAssessorsTemplate
)
